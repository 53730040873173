<template>
  <van-action-sheet v-model:show="show" class="shopUp">
    <div class="headBox">
      <span>
        {{ $fanyi("筛选") }}
      </span>
      <div class="closeBtn" @click="show = false">
        <img :src="require('@/assets/icon/tanchaunbgchahao.png')" alt="" />
      </div>
    </div>
    <div class="priceFilteringBox">
      <div class="searchItem">
        <div class="priceFilteringTitle">{{ $fanyi("价格区间") }}</div>
        <div class="priceRangeBox">
          <input
            type="text"
            class="inputBox"
            v-model="oform.priceStart"
            oninput="value=value.replace(/[^0-9]/g,'')"
            @input="!isNaN(oform.priceStart) ? '' : (oform.priceStart = null)"
          />

          <span class="delimiter"></span>

          <input
            type="text"
            class="inputBox"
            v-model="oform.priceEnd"
            oninput="value=value.replace(/[^0-9]/g,'')"
            @input="!isNaN(oform.priceEnd) ? '' : (oform.priceEnd = null)"
          />
        </div>
      </div>
      <div class="searchItem">
        <div class="priceFilteringTitle">{{ $fanyi("商品名") }}</div>
        <textarea v-autoHeightForLineBreak v-model="oform.keyword"></textarea>
      </div>
      <div class="btnList">
        <button @click="reset" class="success">{{ $fanyi("重置") }}</button>
        <button @click="confirm" class="danger">{{ $fanyi("确定") }}</button>
      </div>
    </div>
  </van-action-sheet>
</template>
<script setup>
import { getCurrentInstance, ref, defineEmits } from "vue";
defineEmits(["save_chiose"]);

const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const show = ref(false);
const oform = ref({});
//------------------------ methods -------------------------------------
const open = (inform) => {
  show.value = true;
  oform.value = JSON.parse(JSON.stringify(inform));
};

const confirm = () => {
  proxy.$emit("save_chiose", oform.value);
  show.value = false;
};

const reset = () => {
  proxy.$emit("save_chiose", "reset");
  show.value = false;
};
//------------------------ pageLoad -------------------------------------
defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.headBox {
  height: 88px;
  padding-top: 40px;
  text-align: center;
  top: 0px;
  position: sticky;
  background-color: white;
  z-index: 1;
  span {
    font-size: 30px;
    line-height: 1;
  }
  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 25px 30px;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
.priceFilteringBox {
  position: relative;
  height: 100%;
  .searchItem {
    padding: 30px 0;
    margin: 0 30px 10px;
    border-bottom: 1px solid #dfdfdf;
    .priceFilteringTitle {
      line-height: 1;
      font-size: 28px;
      color: #999999;
      margin-bottom: 30px;
    }
    .priceRangeBox {
      display: flex;
      align-items: center;
      .inputBox {
        border-radius: 6px;
        border: 1px solid #dfdfdf;
        width: 180px;
        height: 53px;
        font-size: 28px;
      }
      .delimiter {
        height: 1px;
        width: 13px;
        background-color: #999;
        margin: 0 20px;
      }
      button {
        width: 108px;
        height: 60px;
        background: #b4272b;
        border-radius: 6px;
        font-size: 24px;
        color: #fff;
        margin-left: 30px;
        &:active {
          opacity: 0.7;
        }
      }
    }
    .searchbtnList {
      display: flex;

      .filter_button {
        padding: 0 20px;
        height: 53px;
        background: #f2f2f2;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        font-size: 28px;
      }
      .filter_button.active {
        color: #b4272b;
      }
    }
    textarea {
      height: 53px;
      padding: 6px 10px;
      width: 100%;
      line-height: 35px;
      resize: none;
      font-size: 28px;
      overflow: hidden;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
    }
  }
  .btnList {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 65px;

    button {
      width: 280px;
      height: 70px;
      border-radius: 6px;
      font-size: 28px;
      &:active {
        opacity: 0.7;
      }
    }
    button.success {
      background: #27b470;
      color: white;
    }
    button.danger {
      background-color: #b4272b;
      color: white;
    }
  }
}
</style>
<style lang="scss">
.shopUp {
  .van-action-sheet__content {
    height: 70vh;
    display: flex;
    flex-direction: column;
  }
}
</style>
