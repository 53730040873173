<template>
  <div class="shopPage">
    <!-- 头部2 -->
    <headgoback class="headGundong" iCustomizdde="true">
      <template #default>
        <span> {{ $fanyi("店铺信息") }} </span>
      </template>
    </headgoback>
    <div class="shopName">
      {{ $route.query.shopName }}
    </div>
    <!-- 筛选 -->
    <div class="productScreening" v-show="show_productScreening">
      <!-- 筛选的菜单展示 -->
      <div>
        <div class="ScreenBtnList">
          <div
            class="ScreenBtn"
            :class="{
              comprehensiveactive: searchsort,
            }"
            @click.stop="opentoggle('comprehensive')"
          >
            <span>{{ searchsort_findName(searchsort) }}</span>
            <img
              style="width: 0.17333rem"
              v-if="searchsort"
              :src="require('@/assets/icon/reddrowDown.png')"
              alt=""
            />
            <img
              style="width: 0.17333rem"
              v-else
              :src="require('@/assets/icon/shaixuan.png')"
              alt=""
            />
          </div>

          <div
            class="ScreenBtn"
            :class="{
              priceFilteringactive: priceStart || priceEnd,
            }"
            @click="opentoggle('priceFiltering')"
          >
            <span>{{ $fanyi("筛选") }}</span>
            <img
              style="width: 0.29333rem"
              v-if="priceStart || priceEnd"
              :src="require('@/assets/icon/shao.png')"
              alt=""
            />
            <img
              style="width: 0.29333rem"
              v-else
              :src="require('@/assets/icon/shaixunloudou.png')"
              alt=""
            />
          </div>
        </div>
        <van-dropdown-menu ref="dropdownMenu">
          <van-dropdown-item
            title="otrue"
            @open="shaixuanChiose = true"
            @close="shaixuanChiose = false"
          >
            <div class="comprehensiveBtnlistBox">
              <div
                class="comprehensiveBtn"
                :class="{
                  active: searchsort == '',
                }"
                @click="
                  searchsort = '';
                  filterProducts();
                "
              >
                {{ $fanyi("综合(综合排名)") }}
              </div>
              <div
                class="comprehensiveBtn"
                :class="{
                  active: searchsort == 'desc',
                }"
                @click="
                  searchsort = 'desc';
                  filterProducts();
                "
              >
                {{ $fanyi("按价格从高到低") }}
              </div>
              <div
                class="comprehensiveBtn"
                :class="{
                  active: searchsort == 'asc',
                }"
                @click="
                  searchsort = 'asc';
                  filterProducts();
                "
              >
                {{ $fanyi("按价格从低到高") }}
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
    </div>
    <van-pull-refresh
      v-model="refreshing"
      head-height="100"
      :loosing-text="$fanyi('释放即可刷新...')"
      :pulling-text="$fanyi('下拉刷新')"
      :loading-text="$fanyi('加载中')"
      :success-text="$fanyi('加载成功')"
      @refresh="onRefresh"
    >
      <van-list
        v-model:loading="loading"
        v-model:error="error"
        offset="200"
        :error-text="$fanyi('暂无数据')"
        :finished="taobaofinished"
        :loading-text="$fanyi('加载中')"
        :finished-text="$fanyi('没有更多了')"
        @load="onLoad"
        v-if="goodsList.length"
      >
        <div class="list">
          <div
            class="item"
            v-for="item in goodsList"
            @click="$fun.toCommodityDetails(item.goodsId, item.shopType)"
            :key="item.goodsId"
          >
            <div class="top-img">
              <van-image :src="item.imgUrl" lazy-load>
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
            <p class="shoptext">{{ item.titleT }}</p>
            <div class="price_and_xiaoshou">
              <p class="price">
                <span> {{ $fun.ceil(item.goodsPrice) }}{{ $fanyi("元") }}</span>
                ({{
                  $fun.ceil(
                    Number(item.goodsPrice) * $store.state.exchangeRate,
                    0
                  )
                }}
                {{ $fanyi("円") }})
              </p>
              <p class="yuexiaoshou" v-if="item.origin">
                {{ $fanyi("最近售出") }}{{ item.origin.monthSold }}件
              </p>
            </div>
            <!-- 1688原数据没有这几个参数 -->
            <!-- <div class="goods_tag_list">
              <div class="tag_item">
                {{ $fanyi("严选工厂") }}
              </div>
               <div class="tag_item" v-if="item.isJxhy">
                {{ $fanyi("日本热卖") }}
              </div> 
            </div> -->
            <div class="repurchasing" v-if="item.origin">
              {{ $fanyi("复购率") }}<b>{{ item.repurchaseRate || 0 }} </b>%
            </div>
          </div>
        </div>
      </van-list>
      <div class="noData" v-else>
        <img class="noDataImg" :src="require('@/assets/icon/sp.png')" alt="" />
        <span class="noDataSpan">{{ $fanyi("没有找到相关商品") }}</span>
      </div>
    </van-pull-refresh>
    <price_filtering
      :shoptype="shoptype"
      ref="Price_filtering"
      @save_chiose="filterProducts"
    />
  </div>
</template>
<script setup>
// $%4
import headgoback from "../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import price_filtering from "./components/price_filtering.vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const loading = ref(true);
const error = ref(false);
const taobaofinished = ref(false);
const refreshing = ref(false);
const goodsList = ref([
  { goodsPrice: 0 },
  { goodsPrice: 0 },
  { goodsPrice: 0 },
  { goodsPrice: 0 },
  { goodsPrice: 0 },
]);
const page = ref(0);
const shop_name = ref("");
const searchsort = ref(); //商品排序方式 传空是综合 , asc:从低到高 , desc:从高到低
const priceStart = ref(); //价格
const priceEnd = ref(); //价格
const shaixuanChiose = ref(false);
const searchsort_arr = ref([
  {
    key: proxy.$fanyi("综合(综合排名)"),
    searchValue: null,
    value: "",
  },
  {
    key: proxy.$fanyi("价格从低到高"),
    searchValue: { price: "asc" },
    value: "asc",
  },
  {
    key: proxy.$fanyi("价格从高到低"),
    searchValue: { price: "desc" },
    value: "desc",
  },
]);
const keyword = ref();
const show_productScreening = ref(true);
//------------------------ methods -------------------------------------
const searchsort_findName = (v) => {
  let find_result = searchsort_arr.value.find((item) => item.value == v);
  return find_result ? find_result.key : proxy.$fanyi("综合(综合排名)");
};

const onLoad = async (type) => {
  if (type == "reload") {
    proxy.$toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
      loadingType: "spinner",
    });
    page.value = 1;
  } else {
    page.value++;
  }
  let forsort = searchsort_arr.value.find((ff) => ff.value == searchsort.value);
  if (forsort && forsort.searchValue) {
    forsort = JSON.stringify(forsort.searchValue);
  } else {
    forsort = searchsort.value;
  }
  let res = "";
  if (proxy.$route.query.shopId) {
    res = await proxy.$api.sellerOfferList({
      shop_id: proxy.$route.query.shopId,
      shop_type: proxy.$route.query.shopType,
      sort: forsort,
      keyword: keyword.value,
      priceStart: priceStart.value,
      priceEnd: priceEnd.value,
      page: page.value,
    });
  } else {
    res = await proxy.$api.getShopAllGoods({
      shop_name: proxy.$route.query.shopName,
      shop_type: proxy.$route.query.shopType,
      sort: forsort,
      keyword: keyword.value,
      page: page.value,
    });
  }

  proxy.$toast.clear();
  refreshing.value = false;
  loading.value = false;
  if (res.code != 0) {
    taobaofinished.value = true;
    error.value = true;
    return;
  }

  if (res.data.goods && res.data.goods.length < 20) {
    taobaofinished.value = true;
  }
  if (type == "reload") {
    goodsList.value = res.data.goods;
  } else {
    for (let i in res.data.goods) {
      goodsList.value.push(res.data.goods[i]);
    }
  }
  // .catch((err) => {
  //   proxy.$toast.clear();
  //   refreshing.value = false;
  //   loading.value = false;
  //   taobaofinished.value = true;
  //   error.value = true;
  //   goodsList.value = [];
  // });
};

const onRefresh = () => {
  if (taobaofinished.value) {
    proxy.$toast(proxy.$fanyi("没有更多了"));
    refreshing.value = false;
  } else {
    onLoad("reload");
  }
};

// 监听滚动事判断是否显示筛选
const isshow_productScreening = () => {
  let to_top = 0;
  let to_bottom = 0;
  let after_scrollData = 0;
  document,
    addEventListener("scroll", () => {
      if (proxy.$route.name != "shopGoods") return;
      let osTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (after_scrollData < osTop) {
        //下滚
        let rolling_distance = Math.abs(to_bottom - osTop);
        if (rolling_distance > 500) {
          show_productScreening.value = false;
        }
        to_top = osTop;
      } else {
        //上滚
        let rolling_distance = Math.abs(to_top - osTop);
        if (rolling_distance > 500) {
          show_productScreening.value = true;
        }
        to_bottom = osTop;
      }
      setTimeout(() => {
        after_scrollData = osTop;
      }, 0);
    });
};

// 打开筛选的下拉框
const opentoggle = (type) => {
  if (type == "priceFiltering") {
    proxy.$refs.Price_filtering.open({
      priceEnd: priceEnd.value,
      priceStart: priceStart.value,
      keyword: keyword.value,
    });
  } else {
    if (!shaixuanChiose.value) {
      document.getElementsByClassName("van-dropdown-menu__title")[0].click();
    }
  }
};

const filterProducts = (back_data) => {
  console.log(back_data);
  if (back_data == "reset") {
    keyword.value = "";
    priceStart.value = "";
    priceEnd.value = "";
  } else if (back_data) {
    keyword.value = back_data.keyword;
    priceStart.value = back_data.priceStart;
    priceEnd.value = back_data.priceEnd;
  }
  onLoad("reload");
};

//------------------------ pageLoad -------------------------------------
isshow_productScreening();
proxy.$toast.loading({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
  loadingType: "spinner",
});
onLoad("reload");
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.shopPage {
  background-color: #fafafa;
  min-height: 100vh;
  .shopName {
    background-color: #f0f0f0;
    min-height: 80px;
    display: flex;
    align-items: center;
    font-size: 30px;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 20px 36px;
    line-height: 1.5;
  }
  .productScreening {
    height: 80px;
    padding: 10px 30px;
    background-color: white;
    display: flex;
    position: sticky;
    top: 99px;
    z-index: 3;
    .ScreenBtnList {
      display: flex;
      align-items: center;
      .ScreenBtn {
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background: #f6f6f6;
        border-radius: 6px 6px 0 0;
        margin-right: 20px;
        span {
          font-size: 24px;
        }
        img {
          margin-left: 10px;
        }
      }
      .ScreenBtn.comprehensiveactive {
        color: #b4272b;
      }
      .ScreenBtn.priceFilteringactive {
        color: #b4272b;
      }
    }
    .van-dropdown-menu {
      :deep().van-dropdown-menu__bar {
        height: 0;
      }
      :deep().van-dropdown-item__content {
        margin: 0 !important;
        width: 100%;
        border-radius: 0;
        background: #f6f6f6;
        padding: 10px;
      }
      :deep().van-dropdown-menu__title {
        opacity: 0;
      }
      .comprehensiveBtnlistBox {
        justify-content: space-between;
        .comprehensiveBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          background: #ffffff;
          margin: 10px 20px;
          border-radius: 6px;
          font-size: 24px;
          padding: 0 20px;
          min-width: 208px;
          border: solid 2px white;
        }
        .comprehensiveBtn.active {
          border: 2px solid #b4272b;
          border: 2px solid #b4272b;
          color: #b4272b;
          border-radius: 6px;
        }
      }
    }
  }
  .list {
    max-width: 690px;
    margin: 0 36px;
    display: flex;
    // flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .item {
    margin-bottom: 15px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    overflow: hidden;
    flex: 0 0 328px;
    width: 328px;
    // height: 557px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    padding-bottom: 12px;

    .top-img {
      width: 328px;
      height: 328px;
      background: #ffffff;

      .van-image {
        width: 328px;
        height: 328px;
        background: #ffffff;
      }
    }

    .shoptext {
      width: 100%;
      margin: 19px 0;
      padding-left: 20px;
      padding-right: 11px;
      word-break: break-all;
      text-overflow: ellipsis;
      font-size: 24px;
      font-weight: 400;
      line-height: 26px;
      height: 52px;
      color: #000000;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      /* 超出几行省略 */
      overflow: hidden;
    }
    .price_and_xiaoshou {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 15px 0 20px;
      margin-bottom: 25px;
      .price {
        font-size: 24px;
        display: flex;
        align-items: flex-end;
        font-weight: 600;
        color: #b4272b;

        span {
          font-size: 28px;
          font-weight: 600;
          color: #b4272b;
        }
      }
      .yuexiaoshou {
        font-size: 18px;
        line-height: 1;
        color: #999999;
        font-weight: 400;
        margin-top: 10px;
      }
    }
    .goods_tag_list {
      display: flex;
      padding: 0 20px;

      .tag_item {
        background: #ffefef;
        padding: 0 8px;
        color: #ff4000;
        margin-right: 10px;
        font-size: 20px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .repurchasing {
      margin-left: 20px;
      margin-top: 10px;
      min-width: 154px;
      width: max-content;
      height: 30px;
      padding: 0 8px;
      background: #f6f6f6;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      b {
        font-size: 20px;
        color: #b4272d;
      }
    }
  }
  .noData {
    padding-top: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .noDataImg {
      width: 297px;
      height: 269px;
      margin-bottom: 42px;
    }
    .noDataSpan {
      font-size: 24px;
      line-height: 36px;
      font-weight: 400;
      color: #999999;
      text-align: center;
    }
  }
}
:deep().van-ellipsis {
  display: none;
}
</style>
